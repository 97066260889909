import { styled } from '@mui/system'
import { LinkRoot as MenuLink } from '../../molecules/menu/menu-first-level.styled'
import { ButtonStyled as WishlistIcon } from '../../molecules/mini-wish-list/mini-wish-list.styled'
import { SvgIcon as SearchIcon } from '../../molecules/search/search.styled'
import { MuiStackStyled as Divider } from '../../organisms/header/header-compact.styled'
import { ButtonIcon as AccountIcon } from '../../organisms/header/header.styled'
import { HamburgerButton } from '../../organisms/sidebar-navigation/sidebar-navigation.styled'
import { PINNED, UNPINNED } from './constants'
import { Root as StyledRoot } from './sticky-headroom.styled'

/**
 * mode: 'static' | 'pinned' | 'unpinned'
 * overlap: 'true' | 'false'
 * if mode === 'static' => background-color: transparent
 * if mode === 'pinned' || mode === 'unpinned' => background-color: theme.palette.background.header
 */

export const Root = styled(StyledRoot)`
	${({
		mode,
		overlap,
		theme: {
			palette: {
				primary: { main: primary },
				white: { main: white },
				background: { header },
			},
		},
	}) => {
		if (mode === PINNED || mode === UNPINNED) {
			return `
				background-color: ${header};
			`
		} else {
			// mode === STATIC
			return overlap
				? `
				background-color: ${white};
				${MenuLink} {
					color: ${primary};
				}
				${SearchIcon} {
					color: ${primary};
				}
				${AccountIcon} {
					.MuiSvgIcon-root{
						color: ${primary};
					}
				}
				${WishlistIcon} {
					.MuiBadge-root {
						> .MuiSvgIcon-root {
							color: ${primary};
						}
						> .MuiBadge-badge {
							background-color: ${primary};
						}
					}
				}
				${HamburgerButton} {
					.MuiSvgIcon-root {
						color: ${primary};
					}
				}
				${Divider} {
					> span {
						background-color: ${primary};
					}
				}
			`
				: `
				background-color: ${header};
			`
		}
	}}
	&:hover {
		${({
			overlap,
			theme: {
				palette: {
					primary: { main: primary },
					background: { header },
				},
			},
		}) => {
			if (overlap) {
				return `
					${MenuLink} {
						transition: color 0.2s ease-out;
						.MuiLink-root {
							transition: none;
						}
						color: ${primary};
					}
					${SearchIcon} {
						transition: color 0.2s ease-out;
						color: ${primary};
					}
					${AccountIcon} {
						.MuiSvgIcon-root{
							transition: color 0.2s ease-out;
							color: ${primary};
						}
					}
					${WishlistIcon} {
						.MuiBadge-root >.MuiSvgIcon-root {
							transition: color 0.2s ease-out;
							color: ${primary};
						}
					}
					${HamburgerButton} {
						>.MuiSvgIcon-root {
							color: ${primary};
						}
						.MuiBadge-root >.MuiSvgIcon-root {
							transition: color 0.2s ease-out;
							color: ${primary};
						}
					}
					${Divider} {
						> span {
							transition: background-color 0.2s ease-out;
							background-color: ${primary};
						}
					}
				`
			} else {
				return `
					background-color: ${header};
				`
			}
		}}
	}
`
