import { useConfig } from '@bluheadless/ui-logic/src/providers/config'
import Container from '@mui/material/Container'
import router from 'next/router'
import { useEffect, useState } from 'react'
import { useHoverIntent } from 'react-use-hoverintent'
import MenuFirstLevel from './menu-first-level'
import { defaultProps, propTypes } from './menu-props'
import { MenuDropDownStyled, Overlay } from './menu.styled'

const Menu = ({ additionalContent, dropDownProps, eventType, items, hasOverlay, ...props }) => {
	const {
		dropdownMenu: { showOverlay },
	} = useConfig()

	const [openDropdown, setOpenDropdown] = useState(false)
	const [parentCssClasses, setParentCssClasses] = useState(null)
	const [parentMaxItemsForColumn, setParentMaxItemsForColumn] = useState(null)
	const [dropdownItems, setDropdownItems] = useState(null)
	const [routeChanging, setRouteChanging] = useState(null)
	const [isMenuHovering, menuRef] = useHoverIntent({
		timeout: 50,
		sensitivity: 7,
		interval: 100,
	})

	useEffect(() => {
		if (!isMenuHovering) {
			setOpenDropdown(false)
			setDropdownItems(null)
		}
	}, [isMenuHovering])

	useEffect(() => {
		const onRouteChangeStart = () => {
			setOpenDropdown(false)
			setDropdownItems(null)
			setRouteChanging(true)
		}

		const onRouteChangeComplete = () => {
			setRouteChanging(null)
		}

		router.events.on('routeChangeStart', onRouteChangeStart)
		router.events.on('routeChangeComplete', onRouteChangeComplete)
		router.events.on('routeChangeError', onRouteChangeComplete)

		return () => {
			router.events.off('routeChangeStart', onRouteChangeStart)
			router.events.off('routeChangeComplete', onRouteChangeComplete)
			router.events.off('routeChangeError', onRouteChangeComplete)
		}
	}, [])

	return (
		<>
			{showOverlay && (
				<Overlay
					style={{ display: openDropdown && dropdownItems?.length ? 'block' : ' none' }}
					hasOverlay={!!hasOverlay}
				/>
			)}
			<Container maxWidth={false} ref={menuRef} {...props}>
				{items?.length > 0 && (
					<MenuFirstLevel
						eventType={eventType}
						items={items}
						openDropdown={openDropdown}
						setDropdownItems={setDropdownItems}
						setParentCssClasses={setParentCssClasses}
						setParentMaxItemsForColumn={setParentMaxItemsForColumn}
						toggleDropdown={setOpenDropdown}
						routeChanging={routeChanging}
					/>
				)}

				{openDropdown && dropdownItems && (
					<MenuDropDownStyled
						additionalContent={additionalContent}
						anchor={menuRef?.current}
						items={dropdownItems ? dropdownItems : []}
						open={openDropdown}
						parentCssClasses={parentCssClasses}
						parentMaxItemsForColumn={parseInt(parentMaxItemsForColumn)}
						{...dropDownProps}
					/>
				)}
			</Container>
		</>
	)
}

Menu.defaultProps = defaultProps

Menu.propTypes = propTypes

export default Menu
